
import {Component, Vue} from 'vue-property-decorator';
import CustomPageTitle from "../components/CustomPageTitle.vue";
import {formatBytes, formatISODate} from "../filters/filters";
import {AnnouncementDownloadInfo, DocumentDownloadControllerApi, DocumentDownloadInfo} from "../client";
import http from "../plugins/http";
import {FileDownloadService} from "../service/file-download-service";

@Component({
    components: {
        CustomPageTitle,
    },
    filters: {
        formatBytes
    },
})
export default class AnnouncementDownload extends Vue {
    private documentDownloadControllerApi = new DocumentDownloadControllerApi(undefined, process.env.VUE_APP_API_BASE_URL, http);
    private fileDownloadService = new FileDownloadService();

    private id: number = +this.$route.params.id;
    private hash: string = this.$route.params.hash;

    private info: AnnouncementDownloadInfo | null = null;
    private notFound: boolean = false

    mounted() {
        this.documentDownloadControllerApi.getAnnouncementMessageInfo(this.id, this.hash)
            .then(response => {
                this.info = response.data;

                if (this.info?.announcementInfo) {
                    AnnouncementDownload.truncateTitle(this.info?.announcementInfo)
                }
                if (this.info?.submissionInfo) {
                    AnnouncementDownload.truncateTitle(this.info?.submissionInfo)
                }
            })
            .catch(error => this.notFound = error.response && error.response.status == 404);
    }

    downloadAnnouncement() {
        this.documentDownloadControllerApi.downloadAnnouncementWithHash(this.id, this.hash).then(response => this.fileDownloadService.downloadFile(response.data));
    }

    downloadSubmission() {
        if (this.info?.submissionInfo) {
            this.documentDownloadControllerApi.downloadSubmissionWithHash(this.info.submissionInfo.id, this.info.submissionInfo.hash).then(response => this.fileDownloadService.downloadFile(response.data));
        }
    }

    get announcementExpiryDate(): string | undefined {
        return formatISODate(this.info?.announcementInfo?.expiry);
    }

    get submissionExpiryDate(): string | undefined {
        return formatISODate(this.info?.submissionInfo?.expiry);
    }

    private static truncateTitle(info: DocumentDownloadInfo) {
        const title = info.title;

        if (title && title.length > 100) {
            info.title = `${title.substr(0, 120)}...`
        }
    }
}
